import { motion } from "framer-motion";
import { HTMLAttributes, ReactNode } from "react";

interface MotionProps extends HTMLAttributes<HTMLElement> {
  tag?: keyof JSX.IntrinsicElements;
  children?: ReactNode;
  adoptionClassName?: string;
}

const PageStepAnimation = ({
  tag = "div",
  children,
  adoptionClassName,
}: MotionProps): JSX.Element => {
  const Tag = motion[tag] as keyof JSX.IntrinsicElements;
  const motionProps = {
    initial: { opacity: 0, x: 200 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -200 },
    transition: { type: "spring", duration: 1 },
  };

  return (
    <Tag {...motionProps} className={adoptionClassName}>
      {children}
    </Tag>
  );
};

export default PageStepAnimation;
